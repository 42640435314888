import React, { useState } from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { Modal, NamedLink } from '../../../components';

import { ReactComponent as SuccessModalIcon } from "./successPublish.svg";
import { PublishedListingModalStorageKey } from '../../../util/misc';
import css from './PublishedListingModal.module.css';

const PublishedListingModal = (props) => {
    const {
        userType,
        onManageDisableScrolling,
    } = props;

    const [publishedListingModalOpen, setPublishedListingModalOpen] = useState(true);

    const newListingLink = <NamedLink name="NewListingPage">
        <FormattedMessage id={`PublishedListingModal.newListingLink.${userType}`} />
    </NamedLink>

    return <Modal
        id="showPublishedListingModal"
        isOpen={publishedListingModalOpen}
        onClose={() => {
            setPublishedListingModalOpen(false);
            if (typeof window !== "undefined") {
                localStorage.removeItem(PublishedListingModalStorageKey);
            };
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
    >
        <div className={css.root}>
            <div className={css.imgContainer}>
                <SuccessModalIcon />
            </div>
            <h2 className={css.title}>
                <FormattedMessage id={`PublishedListingModal.title.${userType}`} />
            </h2>
            <div className={css.text}>
                <FormattedMessage
                    id={`PublishedListingModal.text1.${userType}`}
                    values={{ newListingLink }}
                />
            </div>
            <div className={css.text}>
                <FormattedMessage
                    id={`PublishedListingModal.text2.${userType}`}
                    values={{ newListingLink }}
                />
            </div>
        </div>
    </Modal>
}

export default PublishedListingModal;
